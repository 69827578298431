<script setup>
import ScrollPanel from "primevue/scrollpanel";

defineProps(['companyName', 'signature', 'estimateFee', 'supplementFee']);

const agreementDate = new Date().toDateString()
</script>
<style scoped>
.scroll-panel {
    width: 100%;
    height: 400px;
    background-color: #f1f1f1;
    padding: 1rem;
}
</style>
<template>
    <ScrollPanel class="scroll-panel mt-4 rounded-2xl">
        <div id="agreement-pdf">
            <h1 class="text-2xl font-bold mb-4">Insurance Claim Supplementation Master Services Agreement</h1>
            <p class="text-gray-600 mb-4">This Agreement is entered into as of <strong>{{ agreementDate }}</strong> (the
                “Effective Date”) between <strong>{{ companyName }}</strong> (“Contractor named on form”) and <strong>LMJ Supplements, LLC dba
                    Claim Connection</strong> (“Agent”) under the following circumstances:</p>

            <h2 class="text-xl font-semibold mt-6 mb-2">Background</h2>
            <p class="text-gray-600 mb-4">Contractor is an exterior home and property improvement company that specializes
                in the repair and replacement of roofing, windows, siding, gutters, and masonry.</p>
            <p class="text-gray-600 mb-4">Agent is an insurance claim supplementation service provider that reviews and
                analyzes property damage information and performs project estimates to restore damaged property to its
                original condition.</p>

            <h2 class="text-xl font-semibold mt-6 mb-2">Agreement</h2>

            <h3 class="text-lg font-semibold mt-4 mb-2">1. Term</h3>
            <p class="text-gray-600 mb-4">This Agreement is effective for one year from the Effective Date.</p>
            <p class="text-gray-600 mb-4">This Agreement applies to all Assigned Claims agreed to within the term of this
                Agreement until completion of each Assigned Claim.</p>
            <p class="text-gray-600 mb-4">This Agreement will renew on an annual basis unless either party provides notice
                of its intent not to renew this Agreement. Notice must be provided at least 60 days before the renewal date.
                If either party elects not to renew this Agreement, the terms of this Agreement remain applicable until all
                Assigned Claims executed under this Agreement are completed or terminated.</p>

            <h3 class="text-lg font-semibold mt-4 mb-2">2. Assigned Claims</h3>
            <p class="text-gray-600 mb-4">During the term of this Agreement, Contractor may assign to Agent certain claims.
                For each Assigned Claim, Agent will perform the services described in Section 4 (the “Services”).</p>

            <h3 class="text-lg font-semibold mt-4 mb-2">3. Payments</h3>
            <p class="text-gray-600 mb-4">For each Assigned Claim, Contractor will pay Agent a Written Estimate Fee and, if
                applicable, a Settled Supplement Fee.</p>
            <p class="text-gray-600 mb-4">
                The Written Estimate Fee is <strong class="text-xl text-primary">${{ estimateFee }}</strong> for single residential or <strong class="text-xl text-primary">$500 to $1,000</strong>
                per building for commercial roofing type/layout and will be paid within 14 days after Contractor receipt of
                Agent’s Written Estimate on an Assigned Claim.
            </p>
            <p class="text-gray-600 mb-4">The Settled Supplement Fee is <strong class="text-xl text-primary">{{ supplementFee }}%</strong> of the difference between the
                original authorized insurance amount (as stated on each Assigned Claim) and the settled amount agreed to by
                the insurer on account of the Services. Contractor will pay Agent the Settled Supplement Fee within 30 days
                after Contractor receives authorization from the insurer for the settled amount.</p>
            <p class="text-gray-600 mb-4">In the event that a claim is approved for a replacement and the Agent is able to
                prove that a full replacement is warranted, a flat fee of 5 percent of the total/final settled amount will
                be billed to Contractor. Contractor will pay Agent the Settled Supplement Fee within 30 days after
                Contractor receives authorization from the insurer for the settled amount.</p>

            <h3 class="text-lg font-semibold mt-4 mb-2">4. Duties and Services of Agent</h3>
            <ul class="list-disc list-inside text-gray-600 mb-4">
                <li class="mb-2"><strong>Review of Claim File:</strong> Upon receipt of an Assigned Claim, Agent will
                    thoroughly review and analyze all documents submitted with the Assigned Claim and any documents provided
                    thereafter by Contractor or the site owner.
                </li>
                <li class="mb-2"><strong>Report; Estimate:</strong> Agent will provide to Contractor a comprehensive and
                    accurate estimate for that supplemental work (“Written Estimate”). Agent’s recommendations and Written
                    Estimate will comply in all respects with all applicable laws, statutes, ordinances, codes, rules and
                    regulations, and lawful orders of public authorities. Agent’s Written Estimates are for review and
                    summary purposes only and are not considered insurance adjustments or as interpretations of insurance
                    policies. Agent and its employees and contractors are not to act as public adjusters in their
                    performance of the Services. Agent will complete all Services for each Assigned Claim within the time
                    period required by the Assigned Claim or, if no such time period is stated, within a reasonable time.
                </li>
                <li class="mb-2"><strong>Coordination with Insurer:</strong> If requested by Contractor, Agent will work
                    with the insurer associated with the Assigned Claim and endeavor to expeditiously obtain the insurer’s
                    approval of the recommended additional work. Agent will respond to any request from the insurer for more
                    documentation or information within a reasonable amount of time, but in no event more than two business
                    days later.
                </li>
                <li class="mb-2"><strong>Use of “ ” Brand:</strong> In any interaction between Agent and any other party
                    (including, without limitation, the insurer, the site owner, or a government official) regarding an
                    Assigned Claim, whether in writing, by telephone, or in person, the Agent will do so in the name of
                    Contractor only and only to the extent authorized by Contractor and necessary to perform its Services.
                    If requested by Contractor, Agent and its employees and contractors will use only -branded vehicles,
                    apparel, and work-related items such as writing instruments and notepads in the performance of its
                    services.
                </li>
                <li class="mb-2"><strong>Indemnification:</strong> To the fullest extent permitted by law, Agent will
                    indemnify, defend, and hold harmless LMJ Supplements, LLC from and against any and all liability,
                    losses, suits, claims, damages, and expenses, including reasonable attorneys’ fees, to the extent caused
                    by or allegedly caused by the negligence of Agent or its employees, contractors, or others for which
                    Agent is responsible.
                </li>
            </ul>

            <h3 class="text-lg font-semibold mt-4 mb-2">5. Duties of Customer</h3>
            <p class="text-gray-600 mb-4">Contractor will consult with Agent as appropriate regarding requirements for each
                Assigned Claim, including Contractor objectives, schedule, constraints, and other criteria.</p>

            <h3 class="text-lg font-semibold mt-4 mb-2">6. Termination</h3>
            <ul class="list-disc list-inside text-gray-600 mb-4">
                <li class="mb-2">Contractor may terminate this Agreement or any Assigned Claim at any time for any reason
                    upon three-days’ advance written notice to Agent.
                </li>
                <li class="mb-2">Contractor may terminate this Agreement and any Assigned Claim immediately, with or without
                    notice, if Agent defaults on any of its duties or obligations. Upon such termination, Agent will
                    immediately stop all work and will be entitled to recover from Agent all damages, including without
                    limitation reasonable attorneys’ fees.
                </li>
                <li class="mb-2">Agent may terminate this Agreement upon 30-days’ advance written notice to Contractor if
                    Contractor defaults on any of its duties or obligations.
                </li>
            </ul>

            <h3 class="text-lg font-semibold mt-4 mb-2">7. Confidentiality</h3>
            <p class="text-gray-600 mb-4">Agent will keep the specific terms and conditions of this Agreement confidential.
                Agent may, during the course of Agent’s performance of the Services, have access to, and acquire knowledge
                of or from, materials, data, strategies, systems, or other information relating to the Services or
                Contractor or its parent, related, affiliated, or subsidiary companies, which may not be accessible or known
                to the general public (“Confidential Information”). Agent will keep any such knowledge acquired by it
                confidential and will not use, publish, or divulge it to any other person or entity without prior written
                consent, which permission Contractor may withhold in its sole discretion. Upon Customer request, Agent will
                immediately return to Customer or destroy all documents (including copies) or other physical evidence of all
                Confidential Information in Agent’s possession or in the possession of any of Agent’s employees, agents, or
                representatives (including, without limitation, all copies, transcriptions, notes, extracts, analyses,
                compilations, or other documents, records, or data prepared by or for Agent) which contain or otherwise
                reflect or are generated from the Confidential Information.</p>

            <h3 class="text-lg font-semibold mt-4 mb-2">8. Personnel and Background Checks</h3>
            <p class="text-gray-600 mb-4">Agent represents that all personnel performing any Services have received
                appropriate training for, and are qualified with respect to, the Services, and have all licenses and
                certifications required by applicable laws.</p>

            <h3 class="text-lg font-semibold mt-4 mb-2">9. Miscellaneous</h3>
            <ul class="list-disc list-inside text-gray-600 mb-4">
                <li class="mb-2"><strong>Taxes:</strong> Agent will withhold and timely pay all applicable local, state, and
                    federal income tax and all fringe benefits (vacation, sick leave, retirement plans, etc.) accruing on
                    payroll distributions resulting from the performance of the Services.
                </li>
                <li class="mb-2"><strong>Time of Essence:</strong> The time periods set forth in or established through this
                    Agreement or any Assigned Claim are of the essence of this Agreement.
                </li>
                <li class="mb-2"><strong>Subcontracting of Duties:</strong> Agent will not transfer, convey, subcontract,
                    relinquish, or otherwise dispose of the whole or any part of its duties or rights under this Agreement
                    without Contractor prior written consent.
                </li>
                <li class="mb-2"><strong>Extent of Agreement:</strong> Nothing contained in this Agreement creates a
                    contractual relationship with or a cause of action in favor of a third party against either Contractor
                    or Agent.
                </li>
                <li class="mb-2"><strong>Modification:</strong> This Agreement may not be modified in any respect except by
                    further agreement in writing between the parties.
                </li>
                <li class="mb-2"><strong>Severability:</strong> The invalidity of any provision of this Agreement will not
                    affect the other provisions and this Agreement will be construed in all respects as if any invalid
                    provisions were omitted.
                </li>
                <li class="mb-2"><strong>Choice of Law and Venue:</strong> This Agreement is governed by Ohio law. All
                    disputes between the parties will be brought in a court of competent jurisdiction seated in Franklin
                    County, Ohio. The parties consent to the exclusive jurisdiction of those courts and to Ohio as the
                    exclusive venue for all litigation and arbitration between them.
                </li>
            </ul>

            <div class="mt-10 flex flex-col gap-4">
                <div class="text-lg">Signature</div>
                <img :src="signature" alt="" />
            </div>
        </div>
    </ScrollPanel>
</template>
