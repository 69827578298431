import { createRouter, createWebHistory } from 'vue-router';

import ClientRegistration from '../views/ClientRegistration.vue';
import Success from '../views/success/ClientRegistrationSuccess.vue';
import Error from '../views/Error.vue';
import ServiceRequest from '../views/ServiceRequest.vue';
import CustomizeClientRegistration from '../views/CustomizeClientRegistration.vue';

const routes = [
    {
        path: '/invite',
        component: CustomizeClientRegistration,
        meta: { requiresAuth: true }
    },
    { path: '/client-registration', component: ClientRegistration },
    { path: '/service-request', component: ServiceRequest },
    { name: 'success', path: '/success/:accountId', component: Success, props: true },
    { path: '/error', component: Error }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

router.beforeEach((to, from, next) => {
    if (
        to.matched.some(record => record.meta.requiresAuth)
        && localStorage.getItem('authenticated') !== 'true'
    ) {
        const password = prompt('Please enter the password:');
        if (password === import.meta.env.VITE_ROUTE_PASSWORD) {
            localStorage.setItem('authenticated', 'true');
            next();
        } else {
            next('/error');
        }
    } else {
        next();
    }
});

export default router;
