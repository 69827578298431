<script setup>
import Button from "primevue/button";
import Card from "primevue/card";
import {onMounted, ref, toRefs} from "vue";
import axios from "../../../Axios.js";


const props = defineProps(['accountId']);
const { accountId } = toRefs(props);

const accountName = ref('');
const accountNumber = ref('');
const loading = ref(true);

const goHome = () => {
    window.top.location.href = 'https://claim-connection.com/';
};

const getAccount = async () => {
    const response = await axios.get(`/get-account/${accountId.value}`);
    const data = response.data.account;
    accountName.value = data.Name;
    accountNumber.value = data.AccountNumber;
}

const sendEmails = async () => {
    try {
        const emailAddresses = import.meta.env.VITE_ONBOARDING_EMAILS;

        const response = await axios.post(`/send-email`, {
            emailAddresses: emailAddresses,
            emailSubject: `Account ${accountName.value} ready for onboarding`,
            emailBody: `<br><strong>Account Name:</strong> ${accountName.value}` +
                `<br><strong>Account Number:</strong> ${accountNumber.value}`,
            html: true
        });
        console.log('Email sent successfully:', response.data);
        // Handle success (e.g., show a success message to the user)
    } catch (error) {
        console.error('Error sending email:', error.response?.data?.message || error.message);
        console.error('Full error object:', error);
        // Handle error (e.g., show an error message to the user)
    }
}

onMounted(async () => {
    await getAccount();
    await sendEmails();
    loading.value = false;
});
</script>

<style scoped>

</style>

<template>
    <div class="flex justify-center items-center h-dvh">
        <Card class="w-1/2">
            <template #header>
                <div class="bg-gray-500 h-20 text-green-500 font-bold text-5xl flex justify-center items-center">
                    SUCCESS
                </div>
            </template>
            <template #title>
                Account Created Successfully
            </template>
            <template #subtitle>
                <div class="flex flex-col gap-1">
                    <div class="text-lg" v-if="loading">Fetching account details ...</div>
                    <div v-else>
                        <div><strong>Account Name:</strong> {{ accountName }}</div>
                        <div class="text-lg"><strong>Account Number:</strong> {{ accountNumber }}</div>
                    </div>
                </div>
            </template>
            <template #content>
                <div class="flex justify-center">
                    <div class="my-5">
                        <p class="text-2xl mb-4">Thank You for Trusting Claim Connection!</p>
                        <p class="mb-2">
                            We appreciate you taking the time to complete the form. Your submission has been
                            successfully received.
                        </p>
                        <p class="mb-2">
                            Please take note of your account number: <strong class="text-lg">{{ accountNumber }}</strong>. This number
                            will be used to identify your account in all future communications with Claim Connection.
                        </p>
                        <p class="mb-2">One of our representatives will reach out to guide you through the next steps
                            in our onboarding process. During this call, we will review the details of your account and
                            ensure that everything is in place to provide you with the best possible service.</p>
                        <p class="mb-2">If you have any immediate questions, feel free to visit our website at
                            <a href="https://claim-connection.com/" target="_blank">Claim Connection</a>
                            or contact us directly.
                        </p>
                        <p class="mb-5">Thank you for choosing Claim Connection—we look forward to assisting you!</p>
                        <p class="font-bold">Best regards,<br>
                            The Claim Connection Team</p>

                    </div>
                </div>
            </template>
            <template #footer>
                <div class="flex justify-end gap-4 mt-1">
                    <Button @click="goHome" label="I'm Done" class="w-1/3"/>
                </div>
            </template>
        </Card>
    </div>
</template>
