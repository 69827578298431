import 'primeicons/primeicons.css';
import './assets/main.css';

import { createApp } from 'vue';
import PrimeVue from 'primevue/config';
import Aura from './presets/Aura.js';
import App from './App.vue';
import router from './router';
import VueSignaturePad from 'vue-signature-pad';
import ToastService from "primevue/toastservice";

const app = createApp(App);

app.use(router);

app.use(VueSignaturePad);
app.use(ToastService);

app.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      prefix: 'p',
      darkModeSelector: 'off',
      cssLayer: {
        name: 'primevue',
        order: 'tailwind-base, primevue, tailwind-utilities'
      }
    }
  }
});

app.mount('#app');
