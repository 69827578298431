import axios from 'axios';

const APPLICATION_JSON = 'application/json';

// Create an instance of axios
const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    withXSRFToken: true
});

axiosInstance.interceptors.request.use(function (config) {
    config.headers.Accept = APPLICATION_JSON;
    return config;
});

export default axiosInstance;
